import React from 'react';
import ReactEcharts from "echarts-for-react";

const CompetitorsLandscape = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    const option = {
        grid: {
            left: '3%',
            right: '7%',
            bottom: '3%',
            containLabel: true
        },
        tooltip: {
            // trigger: 'axis',
            showDelay: 0,
            formatter: function (params) {
                if (params.value.length > 1) {
                    return params.seriesName + ' :<br/>'
                        + params.value[0] + 'cm '
                        + params.value[1] + 'kg ';
                }
                else {
                    return params.seriesName + ' :<br/>'
                        + params.name + ' : '
                        + params.value + 'kg ';
                }
            },
            axisPointer: {
                show: true,
                type: 'cross',
                lineStyle: {
                    type: 'dashed',
                    width: 1
                }
            }
        },
        toolbox: {
            feature: {
                dataZoom: {},
                brush: {
                    type: ['rect', 'polygon', 'clear']
                }
            }
        },
        brush: {
        },
        legend: {
            data: ['女性', '男性'],
            left: 'center'
        },
        xAxis: [
            {
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter: '{value} cm'
                },
                splitLine: {
                    show: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter: '{value} kg'
                },
                splitLine: {
                    show: false
                }
            }
        ],
        series: [
            {
                name: '중위험/경계대상',
                type: 'scatter',
                data: [[161.2, 51.6], [167.5, 59.0], [159.5, 49.2], [157.0, 63.0], [155.8, 53.6]],
                markArea: {
                    silent: true,
                    itemStyle: {
                        color: 'transparent',
                        borderWidth: 1,
                        borderType: 'dashed'
                    },
                    data: [[{
                        name: '중위험/경계대상',
                        xAxis: 'min',
                        yAxis: 'min'
                    }, {
                        xAxis: 'max',
                        yAxis: 'max'
                    }]]
                },
                markPoint: {
                    data: [
                        {type: 'max', name: '最大值'},
                        {type: 'min', name: '最小值'}
                    ]
                }
            },
            {
                name: '고위험/시장선점',
                type: 'scatter',
                data: [[174.0, 65.6, 'Apple'], [180.3, 83.2, 'Samsung']
                ],
                markArea: {
                    silent: true,
                    itemStyle: {
                        color: 'transparent',
                        borderWidth: 1,
                        borderType: 'dashed'
                    },
                    data: [[{
                        name: '고위험/시장선점',
                        xAxis: 'min',
                        yAxis: 'min'
                    }, {
                        xAxis: 'max',
                        yAxis: 'max'
                    }]]
                },
                emphasis: {
                    label: {
                        show: true,
                        formatter: function(param) {
                            return param.data[2];
                        },
                        position: 'top'
                    }
                },
            }
        ]
    };
    const option2 = {
        tooltip: {
            showDelay: 0,
                formatter: function (params) {
                return `${params.value[2]}<br/> 출원건수: ${params.value[0]} 패밀리건수: ${params.value[1]}`;
            },
            axisPointer: {
                show: true,
                    type: 'cross',
                    lineStyle: {
                    type: 'dashed',
                        width: 1
                }
            }
        },
        toolbox: {
            feature: {
                dataZoom: { title: 'zoom' }
            }
        },
        xAxis: {
            name: '출원건수'
        },
        yAxis: {
            name: '패밀리건수'
        },
        series: [{
            symbolSize: 20,
            data: chartData && chartData.data,
            type: 'scatter'
        }]
    };
    return (
        <div className="service-tab-item active">
            <div className="service-title">경쟁자 Landscape</div>
            <div className="service-text">
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={option2}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                {/*{ chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }*/}
            </div>
            <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
            </div>
        </div>
    );
}

export default CompetitorsLandscape;

import React from 'react';
import ReactEcharts from "echarts-for-react";

const TopMajorEnterprises = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    const labelOption = {
        show: true,
        position: 'insideLeft',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 0,
        formatter: '{a}',
        fontSize: 14
    };

    const dataSeries = chartData && chartData.series;
    const series = dataSeries && dataSeries.map((data)=>{
        return {
            ...data,
            type: 'bar',
            label: labelOption
        }
    });
    return (
        <div className="service-tab-item active">
            <div className="service-title">주요기업 Top15</div>
            <div className="service-text">
                <p>해당 기술분야의 주요 기업(출원인)을 알 수 있습니다.</p>
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {},
                                    grid: {
                                        left: '3%',
                                        right: '4%',
                                        bottom: '3%',
                                        containLabel: true
                                    },
                                    xAxis: {
                                        type: 'value',
                                        boundaryGap: [0, 0.01]
                                    },
                                    yAxis: {
                                        type: 'category',
                                        data: ['']
                                    },
                                    series: [...series]
                                }}
                                theme={'roma'}
                            />
                        ): (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
            <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
            </div>
        </div>
    );
}

export default TopMajorEnterprises;


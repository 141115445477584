import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: 'https://api-compa.keywert.com',
    headers: {
        'content-type': 'application/json; charset=utf-8',
        'k-system': '3a1e4136-e802-11ea-920c-027d1efdddf2'
    },
    withCredentials: true
});

/**
 * 요청 인터셉터
 */
axiosInstance.interceptors.request.use(
    function (config) {
        // 요청 바로 직전
        return config;
    },
    function (error) {
        // 요청 에러 처리
        return Promise.reject(error);
    }
);

/**
 * 응답 인터셉터
 */
axiosInstance.interceptors.response.use(
    function (response) {
        // 응답 바로 직전
        return response;
    },

    function (error) {
        // 응답 에러 처리
        if (error.response) {
            if(error.response.data.message){
                alert(error.response.data.message);
            }
            if(error.response.data.code === 'AUTHENTICATION') {
                window.location = '/ipservice/login';
            }
        }
        else if (error.request) {
            console.log(error.request);
        }
        else {
            console.log('Error', error.message);
        }
        return Promise.reject(error);
    }
);

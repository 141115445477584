import React, {Component} from 'react';
import SearchChartView from './SearchChartView'
import { axiosInstance } from '../../common/Request';
import ContentsHeader from "../../common/ContentsHeader";

export default class SimpleSearch extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        this.state = {
            isLoadingSearch: false,
            keywordElementList: [''],
            queryId: null,
            countries: '',
            company: '',
            keywords: null
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(JSON.stringify(this.state.keywordElementList) !== JSON.stringify(nextState.keywordElementList)) {
            return true;
        }
        if(this.state.isLoadingSearch !== nextState.isLoadingSearch) {
            return true;
        }
        if(this.state.queryId !== nextState.queryId) {
            return true;
        }
        return false;
    }

    render() {
        const { keywordElementList, isLoadingSearch, queryId, countries, company, keywords } = this.state;

        return (
            <div className={'service-wrap'}>
                <div className="content">
                    <div className="content-search">
                        <div className={'loader'} style={{ display: isLoadingSearch? 'block': 'none' }}>
                            <div className={'loader-img'}>
                                <img src={'/images/common/loadingBar.gif'} />
                                <div className={'loading-text'}>검색 중입니다.</div>
                            </div>
                        </div>
                        <h3 className="search-head">검색</h3>
                        <div className="search-area">
                            <h4 className="search-title">국가를 선택하세요</h4>
                            <h4 className="search-title error-msg" ref={c=>this.errorRequireCountry=c} style={{ display: 'none' }}><em className={'fa fa-exclamation-triangle'}/> 국가를 하나 이상 선택해주세요.</h4>
                            <ul className="check-list">
                                <li>
                                    <input type="checkbox" id="countries1" name="countries" value={'KR'}/>
                                    <label htmlFor="countries1">한국</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="countries2" name="countries" value={'JP'}/>
                                    <label htmlFor="countries2">일본</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="countries3" name="countries" value={'US'}/>
                                    <label htmlFor="countries3">미국</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="countries4" name="countries" value={'EP'}/>
                                    <label htmlFor="countries4">유럽</label>
                                </li>
                                <li>
                                    <input type="checkbox" id="countries5" name="countries" value={'CN'}/>
                                    <label htmlFor="countries5">중국</label>
                                </li>
                            </ul>
                        </div>
                        <div className="search-area">
                            <h4 className="search-title">기술분야를 선택하세요</h4>
                            <h4 className="search-title error-msg" ref={c=>this.errorRequireTechIpc=c} style={{ display: 'none' }}><em className={'fa fa-exclamation-triangle'}/> 기술분야를 하나 이상 선택해주세요.</h4>
                            <ul className="checked_tabs">
                                <li>
                                    <input type="checkbox" name="techIpcs" id="techIpcs1" value={'EL'}/>
                                    <label htmlFor="techIpcs1">전기전자</label>
                                </li>
                                <li>
                                    <input type="checkbox" name="techIpcs" id="techIpcs2" value={'ME'}/>
                                    <label htmlFor="techIpcs2">기계</label>
                                </li>
                                <li>
                                    <input type="checkbox" name="techIpcs" id="techIpcs3" value={'CH'}/>
                                    <label htmlFor="techIpcs3">화학·바이오</label>
                                </li>
                            </ul>
                        </div>
                        <div className="search-area">
                            <h4 className="search-title">기업명 또는 기술 키워드를 입력하세요.</h4>
                            <h4 className="search-title error-msg" ref={c=>this.errorRequireText=c} style={{ display: 'none' }}><em className={'fa fa-exclamation-triangle'}/> 기업명 또는 기술키워드를 입력해주세요.</h4>
                            <dl className="search-data search-data-name">
                                <dt>기업명</dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.companyName=c} type="text" id={'company'} placeholder=""/>
                                    </div>
                                    <h4 className="search-title error-msg" ref={c=>this.errorCompanyTextLength=c} style={{ display: 'none' }}><em className={'fa fa-exclamation-triangle'}/> 기업명은 두글자 이상 입력해주세요.</h4>
                                </dd>
                            </dl>
                            <dl className="search-data search-data-keyword">
                                <dt>기술 키워드(단어)</dt>
                                <h4 className="search-title error-msg" ref={c=>this.errorKeywordLength=c} style={{ display: 'none' }}><em className={'fa fa-exclamation-triangle'}/> 기술 키워드는 두글자 이상 입력해주세요.</h4>
                                {
                                    keywordElementList.map((keyword, idx)=>{
                                        return (
                                            <dd key={`keywordElementList_${idx}`} className="box-keyword">
                                                <div className="box-input">
                                                    <input type="text" name={'keywords'} placeholder="키워드"/>
                                                    {
                                                        keywordElementList.length === (idx+1)?
                                                            <button className={'search-data-btn search-data-add'} type="button" onClick={this.addKeyword.bind(this)}/>
                                                            : <button className={'search-data-btn search-data-del'} type="button" onClick={this.removeKeyword.bind(this, idx)}/>
                                                    }
                                                </div>
                                            </dd>
                                        )
                                    })
                                }
                            </dl>
                        </div>
                        <div className="search-button">
                            {
                                this.session && this.session.remain <= 0
                                    ? <button type="button" style={{ backgroundColor: '#999999' }}>결과보기</button>
                                    : <button type="button" onClick={this.search.bind(this)}>결과보기</button>
                            }
                        </div>
                    </div>
                    <div className="content-body">
                        {
                            this.session && this.session.remain <= 0
                                ? (
                                    <div className="content-body">
                                        <div className="service-end">
                                            <div className="service-end-title">
                                                무료체험이 종료되었습니다.<br/>정식사용을 원하시는 분은 아래로 연락주세요.
                                            </div>
                                            <div className="service-end-contact">
                                                E. crm@wert.co.kr<br/>T. 02-521-0110
                                            </div>
                                            <div className="service-end-text">다양한 특허검색과 분석이 필요하시면 키워트를 체험해보세요.</div>
                                            <div className="service-end-btn">
                                                <a href="https://www.keywert.com">키워트 바로가기</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                                : <SearchChartView queryId={queryId} countries={countries} company={company} keywords={keywords}/>
                        }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 홈화면 이동
     */
    goHome() {
        this.props.history.push('/ipservice/');
    }

    /**
     * 검색
     */
    search() {
        const params = this.searchValidation();
        if(!params) return;
        this.setState({ isLoadingSearch: true });
        this.clearSearchData();
        axiosInstance.post('/v1/compa/search', JSON.stringify({
            countries: params.countries,
            techIpcs: params.techIpcs,
            keywords: params.keywords,
            company: this.companyName.value
        }))
            .then((result)=>{
                console.log('result :', result);
                this.setState({
                    queryId: result.data.queryId,
                    countries: params.countries.join(','),
                    company: this.companyName.value,
                    keywords: params.keywords
                });
            })
            .catch((err)=>{
                this.clearSearchData();
            }).finally(()=>{
                this.setState({ isLoadingSearch: false });
            })
    }

    /**
     * 검색관련 데이터 초기화
     */
    clearSearchData() {
        this.setState({
            queryId: null,
            countries: '',
            company: '',
            keywords: null
        });
    }

    /**
     * 검색 유효성 체크
     * @return {{techIpcs: *[], keywords: *[], countries: *[]}|null}
     */
    searchValidation() {
        let result = null;
        let invalidKeywordCnt = 0;
        const countries = [...document.querySelectorAll('[name=countries]:checked')].map((country)=>{
            return country.value;
        });
        const techIpcs = [...document.querySelectorAll('[name=techIpcs]:checked')].map((techIpc)=>{
            return techIpc.value;
        });
        const keywords = [...document.querySelectorAll('[name=keywords]')].filter((keyword)=>{
            return (keyword.value !=='');
        }).map((keyword)=>{
            return keyword.value;
        });

        for(let i=0; i < keywords.length; i++) {
            if(keywords[i].length < 2) {
                invalidKeywordCnt++;
            }
        }

        if(countries.length === 0 || techIpcs.length === 0
            || (!this.companyName.value && keywords.length === 0)
            || invalidKeywordCnt > 0
            || (this.companyName.value && this.companyName.value.length < 2)) {

            this.errorRequireCountry.style.display = (countries.length === 0)? 'block': 'none'; // 국가 선택
            this.errorRequireTechIpc.style.display = (techIpcs.length === 0)? 'block': 'none'; // 기술 선택
            this.errorRequireText.style.display = (!this.companyName.value && keywords.length === 0)? 'block': 'none'; // 기술키워드 입력
            this.errorCompanyTextLength.style.display = (this.companyName.value && this.companyName.value.length < 2)? 'block': 'none'; // 기업명 입력길이
            this.errorKeywordLength.style.display = invalidKeywordCnt > 0? 'block': 'none'; // 기술키워드 입력길이

        } else {
            this.errorRequireCountry.style.display = 'none'; // 국가 선택
            this.errorRequireTechIpc.style.display = 'none'; // 기술 선택
            this.errorRequireText.style.display = 'none'; // 기술키워드 입력
            this.errorCompanyTextLength.style.display = 'none'; // 기업명 입력길이
            this.errorKeywordLength.style.display = 'none'; // 기술키워드 입력길이

            result = { countries, techIpcs, keywords };
        }

        return result;
    }

    /**
     * 키워드 추가
     */
    addKeyword() {
        this.setState({
            keywordElementList: [ ...this.state.keywordElementList, '' ]
        });
    }

    /**
     * 키워드 삭제
     */
    removeKeyword(idx) {
        const keywordElementList = JSON.parse(JSON.stringify(this.state.keywordElementList));

        keywordElementList.splice(idx, 1);
        this.setState({
            keywordElementList: keywordElementList
        });
    }
}

import React, {Component} from 'react';

export default class TopicClassification extends Component {
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.chartData && !this.isEmptyObject(nextProps.chartData) && this.props.chartData !== nextProps.chartData) {
            window.LDAvis('#ldavis', nextProps.chartData);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.isLoading !== nextProps.isLoading) {
            return true;
        }
        if(this.props.chartData !== nextProps.chartData) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="service-tab-item active">
                <div className="service-title">주제어 분류</div>
                <div className="service-text">
                </div>
                <div className="service-result">
                    <div className={'loader'} style={{ display: this.props.isLoading? 'block': 'none' }}>
                        <div className={'loader-img'}>
                            <img src={'/images/common/loadingBar.gif'} />
                            <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '800px', position: 'relative', overflow: 'auto' }}>
                        <div id={'ldavis'} style={{ width: '100%', height: '100%', position: 'absolute' }}></div>
                    </div>
                    {/*{ this.props.chartData? <a href="#" className="service-result-down" onClick={()=>{this.props.onExcelDownload(this.props.chartType, this.props.chartData)}}>엑셀다운로드 받기</a>: null }*/}
                </div>
                <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
                </div>
            </div>
        );
    }

    componentDidMount() {
        if(this.props.chartData) {
            window.LDAvis('#ldavis', this.props.chartData);
        }
    }

    isEmptyObject(data) {
        return Object.keys(data).length === 0;
    }
}


import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

window.onload = function () {
  // Dynamic load JS File
  const dynamicLoadJs = function (url) {
    var fileref = document.createElement('script');
    fileref.setAttribute("type", "text/javascript");
    fileref.setAttribute("src", url);
    document.getElementsByTagName("head")[0].appendChild(fileref);
  }

  // Dynamic load CSS File
  const dynamicLoadCss = function (url) {
    var fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute("href", url);
    document.getElementsByTagName("body")[0].appendChild(fileref);
  }

  // 개발기 헤더 이용시 주석을 풀어주세요
  // dynamicLoadCss(`https://static.wehago.com/baseComponents/wehago.global.dev.css`);
  // dynamicLoadJs(`https://static.wehago.com/baseComponents/wehago.global.dev.js`);

  // 운영기 헤더 이용시 주석을 풀어주세요
  // dynamicLoadCss(`https://static.wehago.com/baseComponents/wehago.global.live.css`);
  // dynamicLoadJs(`https://static.wehago.com/baseComponents/wehago.global.live.js`);


}

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home, Login, SimpleSearch } from './pages';
import { Join, MemberInfo, ChangePassword, Withdrawal, FindPassword } from './pages';
import { CommonWrapper } from './common/CommonWrapper';
import { ConfirmAuthority } from './common/ConfirmAuthority';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/ipservice" component={Home} />
        <Route exact path="/ipservice/member/join" component={CommonWrapper(Join)} />
        <Route exact path="/ipservice/member/findPassword" component={CommonWrapper(FindPassword)} />
        <Route exact path="/ipservice/member/memberInfo" component={ConfirmAuthority(CommonWrapper(MemberInfo))} />
        <Route exact path="/ipservice/member/changePassword" component={ConfirmAuthority(CommonWrapper(ChangePassword))} />
        <Route exact path="/ipservice/member/withdrawal" component={ConfirmAuthority(CommonWrapper(Withdrawal))} />
        <Route exact path="/ipservice/login" component={CommonWrapper(Login)} />
        <Route exact path="/ipservice/simpleSearch" component={ConfirmAuthority(CommonWrapper(SimpleSearch))} />
      </Switch>
    </Router>
  );
};

export default App;

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {axiosInstance} from "../../common/Request";

export default class Join extends Component {
    constructor(props) {
        super(props);

        this.authenticationTimer = null;
        this.state = {
            checkUserIdRule: null,  // 아이디 규칙 확인
            isDuplicatedId: null,  // 아이디 중복확인
            checkUserPasswordRule: null,  // 비밀번호 규칙 확인
            isPasswordCheck: null,  // 패스워드 체크
            isUserEmailValidation: null,  // 개인 이메일 유효성
            isMobilePhoneNumberValidation: null,  // 개인 이메일 유효성
            isEmployeeEmailValidation: null,  // 회사 이메일 유효성
            isAuthenticationSuccess: null,  // 인증성공 여부
            activeAuthentication: false // 인증 활성화
        };

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state.checkUserIdRule !== nextState.checkUserIdRule) {
            return true;
        }
        if(this.state.isDuplicatedId !== nextState.isDuplicatedId) {
            return true;
        }
        if(this.state.checkUserPasswordRule !== nextState.checkUserPasswordRule) {
            return true;
        }
        if(this.state.isPasswordCheck !== nextState.isPasswordCheck) {
            return true;
        }
        if(this.state.isUserEmailValidation !== nextState.isUserEmailValidation) {
            return true;
        }
        if(this.state.isMobilePhoneNumberValidation !== nextState.isMobilePhoneNumberValidation) {
            return true;
        }
        if(this.state.isEmployeeEmailValidation !== nextState.isEmployeeEmailValidation) {
            return true;
        }
        if(this.state.isAuthenticationSuccess !== nextState.isAuthenticationSuccess) {
            return true;
        }
        if(this.state.activeAuthentication !== nextState.activeAuthentication) {
            return true;
        }

        return false;
    }

    render() {
        const {
            isDuplicatedId, isPasswordCheck, isUserEmailValidation, isMobilePhoneNumberValidation,
            isEmployeeEmailValidation, isAuthenticationSuccess, activeAuthentication,
            checkUserIdRule, checkUserPasswordRule
        } = this.state;

        return (
            <div>
                <div className="user-area">
                    <h2 className="user-title">회원가입</h2>
                    <form className="user-form">
                        <div className="user-box">
                            <dl className="user-data">
                                <dt>아이디 <i>*</i></dt>
                                <dd>
                                    <div className="box-input box-input-check">
                                        <input
                                            ref={c=>this.userId=c} id={'userId'} type="text" className="input" placeholder="" required={true} title={'아이디'}
                                            onChange={this.checkUserIdRule.bind(this)}
                                        />
                                        <button type="button" name="" onClick={this.checkDuplicatedId.bind(this)} onChange={this.initCheckDuplicatedId.bind(this)}>중복확인</button>
                                    </div>
                                    <div className="box-alert">
                                        <p id={'userIdRule'} className="box-alert-no" style={{ display: checkUserIdRule === false? 'block': 'none' }}>5~20자의 영문 소문자, 숫자만 사용 가능합니다.</p>
                                        <p id={'userIdEmpty'} className="box-alert-no" style={{ display: 'none' }}>아이디를 입력해주세요.</p>
                                        <p id={'isDuplicatedCheck'} className="box-alert-no" style={{ display: 'none' }}>중복확인이 필요합니다.</p>
                                        {
                                            isDuplicatedId === false
                                                ? <p className="box-alert-yes">사용가능한 아이디입니다.</p>
                                                : (isDuplicatedId === true
                                                    ? <p className="box-alert-no">이미 사용중인 아이디입니다.</p>
                                                    : null)
                                        }
                                    </div>
                                </dd>
                                <dt>비밀번호 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.userPassword=c} id={'userPassword'} type="password" className="input" required={true} title={'비밀번호'}
                                           onChange={this.checkPasswordRule.bind(this)}
                                        />
                                    </div>
                                    <div className="box-alert">
                                        <p id={'userPasswordEmpty'} className="box-alert-no" style={{ display: 'none' }}>비밀번호를 입력해주세요.</p>
                                        <p id={'userPasswordRule'} className="box-alert-no" style={{ display: checkUserPasswordRule === false? 'block': 'none' }}>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</p>
                                    </div>
                                </dd>
                                <dt>비밀번호 확인 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.userPasswordCheck=c} id={'userPasswordCheck'} type="password" className="input" required={true} title={'비밀번호 확인'}
                                            onChange={this.checkPassword.bind(this)}
                                        />
                                    </div>
                                    <div className="box-alert">
                                        <p id={'userPasswordCheckEmpty'} className="box-alert-no" style={{ display: 'none' }}>비밀번호를 확인해주세요.</p>
                                        {
                                            isPasswordCheck === true
                                                ? <p className="box-alert-yes">비밀번호가 일치합니다.</p>
                                                : (isPasswordCheck === false? <p className="box-alert-no">비밀번호가 일치하지 않습니다.</p>: null)
                                        }
                                    </div>
                                </dd>
                                <dt>이름 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.userName=c} id={'userName'} type="text" className="input" placeholder="" required={true} title={'이름'}/>
                                    </div>
                                    <div className="box-alert">
                                        <p id={'userNameEmpty'} className="box-alert-no" style={{ display: 'none' }}>이름을 입력해주세요.</p>
                                    </div>
                                </dd>
                                <dt>개인 이메일 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.userEmail=c} id={'userEmail'} type="text" className="input" placeholder="" required={true} title={'개인 이메일'}/>
                                    </div>
                                    <div className="box-alert">
                                        <p id={'userEmailEmpty'} className="box-alert-no" style={{ display: 'none' }}>이메일을 입력해주세요.</p>
                                        {
                                            isUserEmailValidation === false
                                                ? <p className="box-alert-no">이메일 형식으로 입력해 주세요.</p>: null
                                        }
                                    </div>
                                </dd>
                                <dt>휴대전화번호 <i>*</i></dt>
                                <dd>
                                    <div className="box-input box-input-tel" style={{ paddingRight: 120 }}>
                                        <input ref={c=>this.mobilePhoneNumber=c} id={'mobilePhoneNumber'} type="text" className="input" placeholder="전화번호 입력" required={true} title={'휴대전화번호'}/>
                                        <button type="button" name="" style={{ width: 120 }} onClick={this.sendAuthenticationNumber.bind(this)}>인증번호 발송</button>
                                    </div>
                                    <div className="box-input box-input-check" style={{ display: activeAuthentication? 'block': 'none' }}>
                                        <input ref={c=>this.authenticationNumber=c} type="text" className="input" placeholder=""/>
                                        <span ref={c=>this.authenticationTimerArea=c} className={'auth-timer'}>3분 0초</span>
                                        <button type="button" name="" onClick={this.checkAuthenticationNumber.bind(this)}>인증</button>
                                    </div>
                                    <div className="box-alert">
                                        { isAuthenticationSuccess === null? <p id={'authenticationRequired'} className="box-alert-no" style={{ display: 'none' }}>본인인증은 필수입니다.</p>: null}
                                        { isAuthenticationSuccess === false? <p className="box-alert-no">인증실패</p>: null }
                                        { isAuthenticationSuccess === true? <p className="box-alert-yes">인증이 완료되었습니다.</p>: null }
                                        { isMobilePhoneNumberValidation === false? <p className="box-alert-no">전화번호 형식으로 입력해 주세요.</p>: null }
                                        <p id={'mobilePhoneNumberEmpty'} className="box-alert-no" style={{ display: 'none' }}>전화번호를 입력해 주세요.</p>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div className="user-box">
                            <dl className="user-data">
                                <dt>회사명 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.companyName=c} id={'companyName'} type="text" className="input" placeholder="회사명 입력" required={true} title={'회사명'}/>
                                    </div>
                                    <div className="box-alert">
                                        <p id={'companyNameEmpty'} className="box-alert-no" style={{ display: 'none' }}>회사명을 입력해주세요.</p>
                                    </div>
                                </dd>
                                <dt>부서명</dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.departmentName=c} type="text" className="input" placeholder="부서명 입력" title={'부서명'}/>
                                    </div>
                                </dd>
                                <dt>직급</dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.positionName=c} type="text" className="input" placeholder="직급 입력" title={'직급'}/>
                                    </div>
                                </dd>
                                <dt>업무용 이메일 <i>*</i></dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.employeeEmail=c} id={'employeeEmail'} type="text" className="input" placeholder="업무용 이메일 입력" required={true} title={'업무용 이메일'}/>
                                    </div>
                                    <div className="box-alert">
                                        <p id={'employeeEmailEmpty'} className="box-alert-no" style={{ display: 'none' }}>업무용 이메일을 입력해주세요.</p>
                                        {
                                            isEmployeeEmailValidation === false
                                                ? <p className="box-alert-no">이메일 형식으로 입력해 주세요.</p>: null
                                        }
                                    </div>
                                </dd>
                                <dt>회사 직통번호</dt>
                                <dd>
                                    <div className="box-input">
                                        <input ref={c=>this.employeePhoneNumber=c} type="text" className="input" placeholder="회사 직통번호 입력" title={'회사 직통번호'}/>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        <div className="user-submit">
                            <div className="box-button">
                                <button type="button" name="" onClick={this.join.bind(this)}>확인</button>
                            </div>
                        </div>
                        <div className="user_etc">
                            <p className="text">이미 회원이신가요?</p>
                            <Link to={'/ipservice/login'} className="btn">로그인하기</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    /**
     * 아이디 생성 규칙 확인
     * @return {boolean}
     */
    checkUserIdRule() {
        document.getElementById('userIdEmpty').style.display = 'none';
        this.initCheckDuplicatedId();

        const idRegExp = /^[a-z0-9]{5,20}/g;

        if(!idRegExp.test(this.userId.value)) {
            this.setState({
                checkUserIdRule: false
            });
            this.userId.focus();
            return false;
        } else {
            this.setState({
                checkUserIdRule: true
            });
        }
    }

    /**
     * 중복확인 초기화
     */
    initCheckDuplicatedId() {
        this.setState({
            isDuplicatedId: null
        });
    }

    /**
     * 아이디 중복확인
     */
    checkDuplicatedId() {
        if(document.getElementById('userId').value) {
            document.getElementById('userIdEmpty').style.display = 'none';
            document.getElementById('isDuplicatedCheck').style.display = 'none';

            axiosInstance.post('/v1/compa/user/duplicate', JSON.stringify({
                id: this.userId.value
            }))
                .then((result)=>{
                    this.setState({
                        isDuplicatedId: false
                    });
                })
                .catch((e)=>{
                    const responseData = e.response.data;
                    if(responseData) {
                        switch (responseData.code) {
                            case 3007: {
                                this.setState({
                                    isDuplicatedId: true
                                });
                                break;
                            }
                            default: {
                                console.error('err: ', e.response);
                            }
                        }
                    } else {
                        console.error('err: ', e);
                    }
                });
        }
    }

    /**
     * 비밀번호 규칙확인
     * @return {boolean}
     */
    checkPasswordRule() {
        const pwRegExp = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

        if(!pwRegExp.test(this.userPassword.value)) {
            this.setState({
                checkUserPasswordRule: false
            });
            this.userPassword.focus();
            return false;
        } else {
            this.setState({
                checkUserPasswordRule: true
            });
        }
    }

    /**
     * 비밀번호 확인
     */
    checkPassword() {
        document.getElementById(`userPasswordCheckEmpty`).style.display = 'none';
        this.setState({
            isPasswordCheck: (this.userPassword.value === this.userPasswordCheck.value)
        });
    }

    /**
     * 인증 타이머
     */
    setAuthenticationTimer() {
        let time = 180;
        let min = 0;
        let sec = 0;

        this.authenticationTimer = setInterval(()=>{
            min = parseInt(time / 60);
            sec = time % 60;

            this.authenticationTimerArea.textContent = `${min}분 ${sec}초`;
            time--;

            if(time < 0) {
                clearInterval(this.authenticationTimer);
                this.authenticationTimerArea.textContent = '시간초과';
                this.setState({
                    isAuthenticationSuccess: false
                })
            }
        }, 1000);
    }

    /**
     * 인증번호 발송
     */
    sendAuthenticationNumber() {
        const phoneNumber = this.mobilePhoneNumber.value.replace(/-/gi, '');

        this.setState({
            isAuthenticationSuccess: null,
            activeAuthentication: true
        });
        this.authenticationNumber.value = '';   // 인증번호 초기화
        clearInterval(this.authenticationTimer);
        this.setAuthenticationTimer();

        axiosInstance.post('/v1/compa/user/sms/register', JSON.stringify({
            mobile: phoneNumber
        }))
            .then(()=>{
                alert('인증번호가 발송되었습니다.');
            });

    }

    /**
     * 인증번호 확인
     */
    checkAuthenticationNumber() {
        const phoneNumber = this.mobilePhoneNumber.value.replace(/-/gi, '');

        axiosInstance.post('/v1/compa/user/sms/valid/register', JSON.stringify({
            mobile: phoneNumber,
            authNo: this.authenticationNumber.value
        }))
            .then(()=>{
                this.setState({
                    isAuthenticationSuccess: true,
                    activeAuthentication: false
                });
            })
            .catch(()=>{
                this.setState({
                    isAuthenticationSuccess: false,
                    activeAuthentication: true
                });
            })
            .finally(()=>{
                this.authenticationNumber.value = '';   // 인증번호 초기화
                clearInterval(this.authenticationTimer);
            });
    }

    /**
     * 유효성 체크
     */
    validation() {
        const idRegExp = /^[a-z][0-9]{5,20}/g;
        const mobileRegExp =/(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
        const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

        const requiredElements = document.querySelectorAll('input[required]');  // 필수 필드

        for(let i=0; i<requiredElements.length; i++) {
            const el = requiredElements[i];

            if(!el.value) { /* null 체크 */
                el.focus();
                document.getElementById(`${el.id}Empty`).style.display = 'block';
                return false;

            } else { /* 각종 유효성 */
                document.getElementById(`${el.id}Empty`).style.display = 'none';

                if(el.id === 'userId') {
                    if(!this.state.checkUserIdRule) { /* 아이디 생성 규칙 확인 */
                        el.focus();
                        return false;
                    }
                    if(this.state.isDuplicatedId === null) { /* 아이디 중복확인 */
                        el.focus();
                        document.getElementById('isDuplicatedCheck').style.display = 'block';
                        return false;
                    } else {
                        document.getElementById('isDuplicatedCheck').style.display = 'none';
                    }
                }
                if(el.id === 'userPassword' && !this.state.checkUserPasswordRule) {  /* 패스워드 규칙 */
                    el.focus();
                    return false;
                }
                if(el.id === 'userPasswordCheck' && !this.state.isPasswordCheck) {  /* 패스워드 확인 */
                    el.focus();
                    return false;
                }
                if(el.id === 'userEmail') {    /* 메일 형식 */
                    if(!emailRegExp.test(el.value)) {
                        this.setState({
                            isUserEmailValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isUserEmailValidation: true
                        });
                    }
                }
                if(el.id === 'mobilePhoneNumber') {    /* 휴대전화번호 형식 */
                    if(!mobileRegExp.test(el.value)) {
                        this.setState({
                            isMobilePhoneNumberValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isMobilePhoneNumberValidation: true
                        });
                    }
                    if(this.state.isAuthenticationSuccess === null) {   /* 본인인증 */
                        document.getElementById('authenticationRequired').style.display = 'block';
                        el.focus();
                        return false;
                    }
                }
                if(el.id === 'employeeEmail') {    /* 메일 형식 */
                    if(!emailRegExp.test(el.value)) {
                        this.setState({
                            isEmployeeEmailValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isEmployeeEmailValidation: true
                        });
                    }
                }
            }
        }

        return true;
    }

    /**
     * 회원가입
     */
    join() {
        if(!this.validation()) {
            return;
        }

        axiosInstance.post('/v1/compa/user', JSON.stringify({
            // 아이디
            id: this.userId.value,
            // 비밀번호
            password: this.userPassword.value,
            // 이름
            name: this.userName.value,
            // 개인 이메일
            email: this.userEmail.value,
            // 회사 이메일
            companyEmail: this.employeeEmail.value,
            // 휴대폰 번호
            mobile: this.mobilePhoneNumber.value,
            // 회사 이름
            companyName: this.companyName.value,
            // 부서명
            companyDepartment : this.departmentName.value,
            // 직급명
            companyPosition : this.positionName.value,
            // 회사 직통번호
            companyMobile : this.employeePhoneNumber.value
        }))
            .then((result)=>{
                console.log('result : ', result);
                alert('회원가입이 완료되었습니다.');
                this.props.history.push('/ipservice/login');
            })
            .catch((e)=>{
                const responseData = e.response.data;
                if(responseData) {
                    switch (responseData.code) {
                        case 3005: {
                            alert(responseData.msg);
                            break;
                        }
                        default: {
                            console.error('err: ', e.response);
                        }
                    }
                } else {
                    alert('회원가입 진행 중 문제가 발생하였습니다.\n잠시 후 다시시도해주세요.');
                }
            })
    }
}

import React from 'react'
import ContentsHeader from "./ContentsHeader";

export function ConfirmAuthority(Component) {

  class InterceptorComponent extends React.Component {
    constructor(props) {
      super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        if(!this.session) {
            this.props.history.push('/ipservice/login');
        }
    }

    render() {
      return (
        <Component {...this.props} />
      )
    }
  }

  return InterceptorComponent

}

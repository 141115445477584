import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {axiosInstance} from "../../common/Request";

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        this.state = {
            isPasswordCheck: null,
            checkPasswordRule: null  // 비밀번호 규칙 확인
        }
    }

    render() {
        const { isPasswordCheck, checkPasswordRule } = this.state;
        return (
            <div>
                <div className="user-area">
                    <ul className="user-tabs">
                        <li><Link to={'/ipservice/member/memberInfo'} className="">내 정보 관리</Link></li>
                        <li><a className="on">비밀번호 변경</a></li>
                    </ul>
                    <form className="user-form">
                        <div className="user-box">
                            <div className="member-area">
                                <dl className="member-data">
                                    <dt className="input-title">현재 비밀번호</dt>
                                    <dd>
                                        <div className="box-input">
                                            <input ref={c=>this.currentPassword=c} id={'password'} type="password" className="input" required={true} placeholder="현재 비밀번호를 입력해주세요."/>
                                        </div>
                                        <div className="box-alert">
                                            <p id={'passwordEmpty'} className="box-alert-no" style={{ display: 'none' }}>현재 비밀번호를 입력해주세요.</p>
                                        </div>
                                    </dd>
                                    <dt className="input-title">새 비밀번호</dt>
                                    <dd>
                                        <div className="box-input">
                                            <input ref={c=>this.newPassword=c} id={'newPassword'} type="password" className="input" required={true} placeholder="새로운 비밀번호를 입력해주세요."
                                                   onChange={this.checkPasswordRule.bind(this)}/>
                                        </div>
                                        <div className="box-alert">
                                            <p id={'newPasswordEmpty'} className="box-alert-no" style={{ display: 'none' }}>새 비밀번호를 입력해주세요.</p>
                                            <p id={'userPasswordRule'} className="box-alert-no" style={{ display: checkPasswordRule === false? 'block': 'none' }}>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</p>
                                        </div>
                                    </dd>
                                    <dt className="input-title">새 비밀번호 확인</dt>
                                    <dd>
                                        <div className="box-input">
                                            <input ref={c=>this.newPasswordCheck=c} id={'newPasswordCheck'} type="password" className="input" required={true} placeholder="새로운 비밀번호를 입력해주세요." onChange={this.checkPassword.bind(this)}/>
                                        </div>
                                        <div className="box-alert">
                                            <p id={'newPasswordCheckEmpty'} className="box-alert-no" style={{ display: 'none' }}>새 비밀번호를 확인해주세요.</p>
                                            {
                                                isPasswordCheck === true
                                                    ? <p className="box-alert-yes">비밀번호가 일치합니다.</p>
                                                    : (isPasswordCheck === false? <p className="box-alert-no">비밀번호가 일치하지 않습니다.</p>: null)
                                            }
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="user-submit">
                            <div className="box-button">
                                <button type="button" name="" onClick={this.changePassword.bind(this)}>확인</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    /**
     * 비밀번호 규칙확인
     * @return {boolean}
     */
    checkPasswordRule() {
        const pwRegExp = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

        if(!pwRegExp.test(this.newPassword.value)) {
            this.setState({
                checkPasswordRule: false
            });
            this.newPassword.focus();
            return false;
        } else {
            this.setState({
                checkPasswordRule: true
            });
        }
    }

    /**
     * 비밀번호 확인
     */
    checkPassword() {
        document.getElementById(`newPasswordCheckEmpty`).style.display = 'none';
        this.setState({
            isPasswordCheck: (this.newPassword.value === this.newPasswordCheck.value)
        });
    }

    /**
     * 유효성 체크
     * @return {boolean}
     */
    validation() {
        const requiredElements = document.querySelectorAll('input[required]');  // 필수 필드

        for(let i=0; i<requiredElements.length; i++) {
            const el = requiredElements[i];

            if (!el.value) { /* null 체크 */
                el.focus();
                document.getElementById(`${el.id}Empty`).style.display = 'block';
                return false;

            } else { /* 각종 유효성 */
                document.getElementById(`${el.id}Empty`).style.display = 'none';

                if(el.id === 'newPassword' && !this.state.checkPasswordRule) {
                    el.focus();
                    return false;
                }

                if (el.id === 'newPasswordCheck' && !this.state.isPasswordCheck) {  /* 패스워드 확인 */
                    el.focus();
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * 비밀번호 변경
     */
    changePassword() {
        if(!this.validation()) return;

        axiosInstance.put('/v1/compa/user/password', JSON.stringify({
            id: this.session.id,
            currentPassword: this.currentPassword.value,
            newPassword: this.newPassword.value
        }))
            .then((result)=>{
                alert('정상적으로 변경되었습니다.');
                this.props.history.push('/ipservice/');
            })
            .catch((e)=>{
                const responseData = e.response.data;
                if(responseData) {
                    switch (responseData.code) {
                        case 3005: {
                            alert('비밀번호를 다시 확인하시고 입력해주시기 바랍니다.');
                            this.currentPassword.focus();
                            break;
                        }
                        default: {
                            console.error('err: ', e.response);
                        }
                    }
                } else {
                    console.error('err: ', e);
                }
            });
    }
}

export default ChangePassword;

import React, {Component} from 'react';
import {axiosInstance} from "../../common/Request";
import {Link} from "react-router-dom";

class MemberInfo extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        this.state = {
            userInfo: null,
            isActiveModifyMemberInfo: false,    // 회원 기본정보 수정 활성화
            isActiveModifyCompanyInfo: false,    // 회원 회사정보 수정 활성화
            isUserEmailValidation: null,  // 개인 이메일 유효성
            isMobilePhoneNumberValidation: null,  // 개인 이메일 유효성
            isEmployeeEmailValidation: null,  // 회사 이메일 유효성
        }
    }

    componentDidMount() {
        this.getMemberInfo();
    }

    render() {
        const {
            userInfo, isActiveModifyMemberInfo, isActiveModifyCompanyInfo,
            isUserEmailValidation, isMobilePhoneNumberValidation, isEmployeeEmailValidation
        } = this.state;
        return (
            <div>
                <div className="user-area">
                    <ul className="user-tabs">
                        <li><a className="on">내 정보 관리</a></li>
                        <li><Link to={'/ipservice/member/changePassword'} className="">비밀번호 변경</Link></li>
                    </ul>
                    <form className="user-form">
                        <div className="user-box">
                            <div className={`personal member-area ${isActiveModifyMemberInfo? 'modify': ''}`}>
                                <div className="member-box member-box-view">
                                    <div className="member-title">
                                        개인정보
                                        <div className="member-btn">
                                            <a className="btn-icon btn-icon-modify" onClick={this.toggleModify.bind(this, 'isActiveModifyMemberInfo')}>수정</a>
                                        </div>
                                    </div>
                                    <dl className="member-data">
                                        <dt>아이디 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.id }</dd>
                                        <dt>이름 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.name }</dd>
                                        <dt>개인 이메일 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.email }</dd>
                                        <dt>휴대전화번호 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.mobile }</dd>
                                    </dl>
                                </div>

                                <div className="member-box member-box-modify">
                                    <div className="member-title">
                                        개인정보
                                        <div className="member-btn">
                                            <a className="btn-icon btn-icon-cancel" onClick={this.toggleModify.bind(this, 'isActiveModifyMemberInfo')}>취소</a>
                                            <a className="btn-icon btn-icon-save" onClick={this.updateMemberInfo.bind(this, 'isActiveModifyMemberInfo')}>저장</a>
                                        </div>
                                    </div>
                                    <dl className="member-data">
                                        <dt>아이디 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.id }</dd>
                                        <dt>이름 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.name }</dd>
                                        <dt className="input-title">개인 이메일 <i>*</i></dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.userEmail=c} id={'userEmail'} type="text" className="input" placeholder="" required={true} defaultValue={userInfo && userInfo.email}/>
                                            </div>
                                            <div className="box-alert">
                                                <p id={'userEmailEmpty'} className="box-alert-no" style={{ display: 'none' }}>이메일을 입력해주세요.</p>
                                                {
                                                    isUserEmailValidation === false
                                                        ? <p className="box-alert-no">이메일 형식으로 입력해 주세요.</p>: null
                                                }
                                            </div>
                                        </dd>
                                        <dt className="input-title">휴대전화번호 <i>*</i></dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.mobilePhoneNumber=c} id={'mobilePhoneNumber'} type="text" className="input" placeholder="" required={true} defaultValue={''}/>
                                            </div>
                                            <div className="box-alert">
                                                { isMobilePhoneNumberValidation === false? <p className="box-alert-no">전화번호 형식으로 입력해 주세요.</p>: null }
                                                <p id={'mobilePhoneNumberEmpty'} className="box-alert-no" style={{ display: 'none' }}>전화번호를 입력해 주세요.</p>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>

                            <div className={`company member-area ${isActiveModifyCompanyInfo? 'modify': ''}`}>
                                <div className="member-box member-box-view">
                                    <div className="member-title">
                                        회사 정보
                                        <div className="member-btn">
                                            <a href="#" className="btn-icon btn-icon-modify" onClick={this.toggleModify.bind(this, 'isActiveModifyCompanyInfo')}>수정</a>
                                        </div>
                                    </div>
                                    <dl className="member-data">
                                        <dt>회사명 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.companyName }</dd>
                                        <dt>부서</dt>
                                        <dd>{ userInfo && userInfo.companyDepartment }</dd>
                                        <dt>직급</dt>
                                        <dd>{ userInfo && userInfo.companyPosition }</dd>
                                        <dt>회사 이메일 <i>*</i></dt>
                                        <dd>{ userInfo && userInfo.companyEmail }</dd>
                                        <dt>직통 전화번호</dt>
                                        <dd>{ userInfo && userInfo.companyMobile }</dd>
                                    </dl>
                                </div>

                                <div className="member-box member-box-modify">
                                    <div className="member-title">
                                        회사 정보
                                        <div className="member-btn">
                                            <a className="btn-icon btn-icon-cancel" onClick={this.toggleModify.bind(this, 'isActiveModifyCompanyInfo')}>취소</a>
                                            <a className="btn-icon btn-icon-save" onClick={this.updateMemberInfo.bind(this, 'isActiveModifyCompanyInfo')}>저장</a>
                                        </div>
                                    </div>
                                    <dl className="member-data">
                                        <dt className="input-title">회사명 <i>*</i></dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.companyName=c} id={'companyName'} type="text" className="input" placeholder="" defaultValue={ userInfo && userInfo.companyName }/>
                                            </div>
                                            <div className="box-alert">
                                                <p id={'companyNameEmpty'} className="box-alert-no" style={{ display: 'none' }}>회사명을 입력해주세요.</p>
                                            </div>
                                        </dd>
                                        <dt className="input-title">부서</dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.departmentName=c} type="text" className="input" placeholder="" defaultValue={ userInfo && userInfo.companyDepartment }/>
                                            </div>
                                        </dd>
                                        <dt className="input-title">직급</dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.positionName=c} type="text" className="input" placeholder="" defaultValue={ userInfo && userInfo.companyPosition }/>
                                            </div>
                                        </dd>
                                        <dt className="input-title">회사 이메일 <i>*</i></dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.employeeEmail=c} id={'employeeEmail'} type="text" className="input" placeholder="" defaultValue={ userInfo && userInfo.companyEmail }/>
                                            </div>
                                            <div className="box-alert">
                                                <p id={'employeeEmailEmpty'} className="box-alert-no" style={{ display: 'none' }}>업무용 이메일을 입력해주세요.</p>
                                                {
                                                    isEmployeeEmailValidation === false
                                                        ? <p className="box-alert-no">이메일 형식으로 입력해 주세요.</p>: null
                                                }
                                            </div>
                                        </dd>
                                        <dt className="input-title">직통 전화번호</dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.employeePhoneNumber=c} type="text" className="input" placeholder="" defaultValue={ userInfo && userInfo.companyMobile }/>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="user_etc">
                            <p className="text">인공지능 기반 IP 전략 서비스를 더 이상 사용하지 않는다면</p>
                            <Link to={'/ipservice/member/withdrawal'} className="btn">회원탈퇴 바로가기</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    toggleModify(stateName) {
        const { userInfo } = this.state;

        /* 초기화 */
        if(!this.state[stateName]) {
            if(stateName === 'isActiveModifyMemberInfo')  {
                this.userEmail.value = userInfo.email;
                this.mobilePhoneNumber.value = '';

            } else if(stateName === 'isActiveModifyCompanyInfo') {
                this.companyName.value = userInfo.companyName;
                this.departmentName.value = userInfo.companyDepartment;
                this.positionName.value = userInfo.companyPosition;
                this.employeeEmail.value = userInfo.companyEmail;
                this.employeePhoneNumber.value = userInfo.companyMobile;
            }
        }
        this.setState({
            [stateName]: !this.state[stateName]
        });
    }

    /**
     * 회원정보 조회
     */
    getMemberInfo() {
        axiosInstance.get('/v1/compa/user/info', {
            params: {
                id: this.session.id,
            }
        })
            .then((result)=>{
                console.log('result : ', result);
                this.setState({
                   userInfo: result.data
                });
            })
            .catch(()=>{

            })
    }

    /**
     * 유효성 체크
     */
    validation(stateName) {
        let groupName = '';
        if(stateName === 'isActiveModifyMemberInfo')  {
            groupName = 'personal';
        } else if(stateName === 'isActiveModifyCompanyInfo') {
            groupName = 'company';
        }
        const mobileRegExp =/(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
        const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        const requiredElements = document.querySelectorAll(`.${groupName} input[required]`);  // 필수 필드

        for(let i=0; i<requiredElements.length; i++) {
            const el = requiredElements[i];

            if(!el.value) { /* null 체크 */
                el.focus();
                document.getElementById(`${el.id}Empty`).style.display = 'block';
                return false;
            } else { /* 각종 유효성 */
                document.getElementById(`${el.id}Empty`).style.display = 'none';

                if(el.id === 'userEmail') {    /* 메일 형식 */
                    if(!emailRegExp.test(el.value)) {
                        this.setState({
                            isUserEmailValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isUserEmailValidation: true
                        });
                    }
                }
                if(el.id === 'mobilePhoneNumber') {    /* 휴대전화번호 형식 */
                    if(!mobileRegExp.test(el.value)) {
                        this.setState({
                            isMobilePhoneNumberValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isMobilePhoneNumberValidation: true
                        });
                    }
                }
                if(el.id === 'employeeEmail') {    /* 메일 형식 */
                    if(!emailRegExp.test(el.value)) {
                        this.setState({
                            isEmployeeEmailValidation: false
                        });
                        el.focus();
                        return false;
                    } else {
                        this.setState({
                            isEmployeeEmailValidation: true
                        });
                    }
                }
            }
        }

        return true;
    }


    /**
     * 회원정보 수정
     */
    updateMemberInfo(stateName) {
        if(!this.validation(stateName)) return;

        let url = '';
        let params = {};
        if(stateName === 'isActiveModifyMemberInfo')  {
            url = '/v1/compa/user';
            params = {
                // id
                id: this.session.id,
                // 개인 이메일
                email: this.userEmail.value,
                // 휴대폰 번호
                mobile: this.mobilePhoneNumber.value,
            }

        } else if(stateName === 'isActiveModifyCompanyInfo') {
            url = '/v1/compa/user/company';
            params = {
                // id
                id: this.session.id,
                // 회사 이메일
                companyEmail: this.employeeEmail.value,
                // 회사 이름
                companyName: this.companyName.value,
                // 부서명
                companyDepartment : this.departmentName.value,
                // 직급명
                companyPosition : this.positionName.value,
                // 회사 직통번호
                companyMobile : this.employeePhoneNumber.value
            }
        }

        axiosInstance.put(url, JSON.stringify(params))
            .then((result)=>{
                alert('정상적으로 수정되었습니다.');
                this.getMemberInfo();
                this.toggleModify(stateName)
            });
    }
}

export default MemberInfo;

import {axiosInstance} from "../../common/Request";

/**
 * 로그아웃
 * @param history
 * @return {Promise<void>}
 */
export function logout(history) {
    return axiosInstance.delete('/v1/access/compa')
        .then((result)=>{
            sessionStorage.setItem('user_info', null);
            window.location = '/ipservice/';
        });
}

/**
 * 사용자 정보조회
 * @param id
 * @return {Promise<AxiosResponse<any>>}
 */
export function getUserInfo(id) {
    return axiosInstance.get('/v1/compa/user/info', {
        params: {
            id,
        }
    });
}

/**
 * 사용자 세션정보
 * @return {Promise<void>}
 */
export function getSessionInfo() {
    return axiosInstance.get('/v1/compa/user')
        .then(result => {
            sessionStorage.setItem('user_info', JSON.stringify(result.data));
        });
}

import React from 'react';
import ReactEcharts from "echarts-for-react";

const NumberOfPatentsPerYear = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    console.log('chartData : ', chartData);
   return (
       <div className="service-tab-item active">
           <div className="service-title">연도별 출원건수</div>
           <div className="service-text">
               <p>연도별 특허건수를 통해서 특허가 얼마나 출원되는지 알 수 있습니다.</p>
               <p>특허는 출원 후 1년 6개월 이후에 공개되니, 작년과 올해는 미공개 구간입니다.</p>
           </div>
           <div className="service-result">
               <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                   <div className={'loader-img'}>
                       <img src={'/images/common/loadingBar.gif'} />
                       <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                   </div>
               </div>
               <div style={{ width: '100%', height: '500px' }}>
                   {
                       chartData ? (
                           <ReactEcharts
                               style={{ width: '100%', height: '100%' }}
                               option={{
                                   tooltip: {
                                       trigger: 'axis',
                                       axisPointer: {
                                           type: 'cross',
                                           crossStyle: {
                                               color: '#999'
                                           }
                                       }
                                   },
                                   toolbox: {
                                       feature: {
                                           saveAsImage: { show: true, title: '이미지 다운로드' }
                                       }
                                   },
                                   legend: {
                                       data: ['출원건수', '증감율']
                                   },
                                   xAxis: [
                                       {
                                           type: 'category',
                                           data: chartData.year,
                                           axisPointer: {
                                               type: 'shadow'
                                           }
                                       }
                                   ],
                                   yAxis: [
                                       {
                                           type: 'value',
                                           name: '출원건수',
                                           axisLabel: {
                                               formatter: '{value} 건'
                                           },
                                           splitLine: {
                                               show: false
                                           }
                                       },
                                       {
                                           type: 'value',
                                           name: '증감율',
                                           axisLabel: {
                                               formatter: '{value}'
                                           },
                                           splitLine: {
                                               show: false
                                           }
                                       }
                                   ],
                                   series: [
                                       {
                                           name: '출원건수',
                                           type: 'bar',
                                           data: chartData.value
                                       },
                                       {
                                           name: '증감율',
                                           type: 'line',
                                           yAxisIndex: 1,
                                           data: chartData.rate
                                       }
                                   ]
                               }}
                               theme={'roma'}
                           />
                       ) : (
                           <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                       )
                   }
               </div>
               { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
           </div>
           <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
           </div>
       </div>
   );
}

export default NumberOfPatentsPerYear;

import React from 'react';
import ReactEcharts from 'echarts-for-react';

const PatentDisputesByCountry = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    const litigation = chartData && chartData.litigation;
    const countries = chartData && chartData.countries;
    let data = chartData && chartData.data;

    data = data && data.map(function (item) {
        return [item[1], item[0], item[2]];
    });

    return (
        <div className="service-tab-item active">
            <div className="service-title">국가별 특허분쟁 현황</div>
            <div className="service-text">
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {
                                        position: 'top',
                                        formatter: function (params) {
                                            return litigation[params.value[0]] + ' '+ params.value[2] + '건';
                                        }
                                    },
                                    grid: {
                                        left: 10,
                                        bottom: 10,
                                        right: 40,
                                        containLabel: true
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: litigation,
                                        boundaryGap: false,
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: '#999',
                                                type: 'dashed'
                                            }
                                        },
                                        axisLine: {
                                            show: false
                                        }
                                    },
                                    yAxis: {
                                        type: 'category',
                                        data: countries,
                                        boundaryGap: false,
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: '#999',
                                                type: 'dashed'
                                            }
                                        },
                                        axisLine: {
                                            show: false
                                        }
                                    },
                                    series: [{
                                        type: 'scatter',
                                        symbolSize: function (val) {
                                            return Math.sqrt(val[2]) / 2 + 10;
                                        },
                                        data: data,
                                        animationDelay: function (idx) {
                                            return idx * 5;
                                        }
                                    }]
                                }}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
            <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
            </div>
        </div>
    );
}

export default PatentDisputesByCountry;

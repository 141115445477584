import React from 'react';
import ReactEcharts from 'echarts-for-react';
import 'echarts-leaflet';
import 'leaflet';


const NumberOfPatentsByCountry = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    return (
        <div className="service-tab-item active">
            <div className="service-title">국가별 출원건수</div>
            <div className="service-text">
                <p>어느 국가에 특허출원이 분포되어 있는지 확인할 수 있습니다.</p>
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    tooltip: {
                                        show: true,
                                        formatter: function (params) {
                                            return params.value[3] + ' : ' + params.value[2];
                                        }
                                    },
                                    series: [{
                                        type: 'scatter',
                                        animation: false,
                                        coordinateSystem: 'leaflet',
                                        data: chartData.data,
                                        symbolSize: function (value) {
                                            return value[2] > 0 ? Math.log(value[2]) * 3 : 0;
                                        },
                                        itemStyle: {
                                            color: 'red',
                                            borderWidth: 0,
                                            borderColor: 'rgba(255, 255, 255, 0.5)'
                                        }
                                    }],
                                    visualMap: {
                                        type: 'continuous',
                                        min: chartData.min,
                                        max: chartData.max,
                                        calculable: true,
                                        inRange: {
                                            color: ['orange', 'red'],
                                            opacity: [0.5, 0.8]
                                        },
                                        dimension: 2
                                    },
                                    leaflet: {
                                        center: [0, 40],
                                        roam: true,
                                        tiles: [{
                                            urlTemplate: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                                        }]
                                    }
                                }}
                                theme={'roma'}
                            >
                            </ReactEcharts>
                        ): (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
            <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
            </div>
        </div>
    )
}

export default NumberOfPatentsByCountry;

import React from 'react';
import ReactEcharts from "echarts-for-react";

const TechnologyLevelByCompany = ({ isLoading, chartData, chartType, onExcelDownload}) => {
    return (
        <div className="service-tab-item active">
            <div className="service-title">기업별 기술 수준</div>
            <div className="service-text">
            </div>
            <div className="service-result">
                <div className={'loader'} style={{ display: isLoading? 'block': 'none' }}>
                    <div className={'loader-img'}>
                        <img src={'/images/common/loadingBar.gif'} />
                        <div className={'loading-text'}>검색 결과를 분석 중입니다.</div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '500px' }}>
                    {
                        chartData ? (
                            <ReactEcharts
                                style={{ width: '100%', height: '100%' }}
                                option={{
                                    grid: {
                                      bottom: 100
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: chartData.data,
                                        axisLabel: {
                                            interval: 0,
                                            rotate: 40
                                        },
                                        splitLine: {
                                            show: false
                                        }
                                    },
                                    yAxis: {
                                        type: 'value'
                                    },
                                    series: [{
                                        data: chartData.value,
                                        type: 'bar',
                                        showBackground: true,
                                        backgroundStyle: {
                                            color: 'rgba(220, 220, 220, 0.8)'
                                        }
                                    }]
                                }}
                                theme={'roma'}
                            />
                        ) : (
                            <div style={{ display: 'inline-block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>검색된 데이터가 없습니다.</div>
                        )
                    }
                </div>
                { chartData? <a href="#" className="service-result-down" onClick={()=>{onExcelDownload(chartType, chartData)}}>엑셀다운로드 받기</a>: null }
            </div>
            <div className="service-result-note">2000년 이후 출원된 기술키워드와 관련된 유사 특허 최대 2만건을 대상으로 합니다
            </div>
        </div>
    );
}

export default TechnologyLevelByCompany;

import React, {Component} from 'react';
import {axiosInstance} from "../../common/Request";

class Withdrawal extends Component {
    constructor(props) {
        super(props);

        const sessionStr = sessionStorage.getItem('user_info');
        const session = sessionStr? JSON.parse(sessionStr): null;

        this.session = session;

        this.state = {
            openModal: false
        }
    }

    render() {
        return (
            <div>
                <div className="user-area">
                    <form className="user-form">
                        <div className="user-box">
                            <div className="member-head">
                                <div className="title">탈퇴안내</div>
                                <div className="text">회원탈퇴를 신청하기 전에 안내사항을 꼭 확인해주세요.</div>
                            </div>
                            <dl className="member-info">
                                <dt>아이디</dt>
                                <dd>{ this.session.id }</dd>
                            </dl>
                            <div className="member-note">
                                <div className="title">유의사항 안내</div>
                                <p className="text">탈퇴 후 동일한 아이디로 다시 가입할 수 없습니다.</p>
                                <p className="text">탈퇴 후 2개월 동안 재가입이 제한됩니다. </p>
                            </div>
                            <div className="member-check">
                                <div className="box-check">
                                    <input type="checkbox" name="confirm" id="confirm" title="안내사항 확인"/>
                                    <label htmlFor="confirm">안내사항을 모두 확인하였으며, 이에 동의합니다.</label>
                                </div>
                            </div>
                            <div className="box-button">
                                <button type="button" name="" onClick={this.toggleModal.bind(this)}>회원탈퇴 신청</button>
                            </div>
                        </div>
                    </form>
                    <div className={`modal_wrap ${this.state.openModal? 'show_modal': ''}`} id="modal_wrap1">
                        <div className="modal_content">
                            <div className="modal_body">
                                <div className="member-area">
                                    <div className="member-head">
                                        <div className="title">탈퇴안내</div>
                                        <div className="text">회원탈퇴를 신청하기 전에 안내사항을 꼭 확인해주세요.</div>
                                    </div>
                                    <dl className="member-data">
                                        <dt>탈퇴할 아이디</dt>
                                        <dd>{ this.session.id }</dd>
                                        <dt className="input-title">비밀번호</dt>
                                        <dd>
                                            <div className="box-input">
                                                <input ref={c=>this.userPassword=c} id={'userPassword'} type="password" className="input" placeholder="" defaultValue=""
                                                    onChange={this.handleChangePassword.bind(this)}/>
                                            </div>
                                            <div className="box-alert">
                                                <p id={'userPasswordEmpty'} className="box-alert-no" style={{ display: 'none' }}>비밀번호를 입력해주세요.</p>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="modal-button">
                                    <button type="button" name="" className="cancel" onClick={this.toggleModal.bind(this)}>취소</button>
                                    <button type="button" name="" onClick={this.withdrawal.bind(this)}>확인</button>
                                </div>
                            </div>
                            <button className="modal_close" onClick={this.toggleModal.bind(this)}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 탈퇴 모달 toggle
     * @return {boolean}
     */
    toggleModal() {
        if(document.querySelectorAll('#confirm:checked').length <= 0) {
            alert('안내사항을 확인하시고 동의해주세요.')
            return false;
        }
        this.setState({
            openModal: !this.state.openModal
        });
    }

    handleChangePassword() {

    }

    /**
     * 탈퇴처리
     */
    withdrawal() {
        if(!this.userPassword.value) {
            document.getElementById('userPasswordEmpty').style.display = 'block';
        }
        axiosInstance.put('/v1/compa/user/withdrawal', JSON.stringify({
            id: this.session.id,
            currentPassword: this.userPassword.value
        }))
            .then((result)=>{
                alert('회원탈퇴가 완료되었습니다.');
                sessionStorage.setItem('user_info', null);
                this.props.history.push('/ipservice/');
            })
            .catch((e)=>{
                const responseData = e.response.data;
                if(responseData) {
                    switch (responseData.code) {
                        case 3005: {
                            alert('비밀번호를 다시 확인하시고 입력해주시기 바랍니다.');
                            this.userPassword.focus();
                            break;
                        }
                        default: {
                            console.error('err: ', e.response);
                        }
                    }
                } else {
                    console.error('err: ', e);
                }
            })
    }
}

export default Withdrawal;

import React, {Component} from 'react';
import {axiosInstance} from "../../common/Request";
import {getLimitedUserInformation} from "../authentication/authenticationUtil";

class FindPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticationSuccess: null,
            checkUserPasswordRule: null,  // 비밀번호 규칙 확인
            limitedMobileNumber: ''
        };
        this.authenticationTimer = null;
    }

    render() {
        return (
            <div>
                <div className="user-area">
                    <h2 className="user-title">비밀번호 찾기</h2>
                    <form className="user-form">
                        <div className="user-box find-step find-step-1">
                            <div className="box-title">비밀번호를 찾고자하는 아이디를 입력해주세요.</div>
                            <div className="box-input box-input-id">
                                <input ref={c=>this.userId=c} id={'userId'} type="text" className="input" placeholder="아이디"/>
                                <button type="button" name="" onClick={this.checkUserId.bind(this)}>다음</button>
                            </div>
                            <div className="box-alert">
                                <p id={'userIdEmpty'} className="box-alert-no" style={{ display: 'none' }}>아이디를 입력해주세요.</p>
                            </div>
                        </div>
                        <div className="user-box find-step find-step-2" style={{ display: 'none' }}>
                            <div className="box-title">{this.userId && this.userId.value} 님, 아래 정보를 입력하시면 <br/>내 정보에 등록된 휴대폰 번호로 인증번호가 전송됩니다</div>
                            <div className="box-input box-input-name">
                                <div className="box-tel" style={{
                                    marginBottom: 0,
                                    fontSize: '16px',
                                    lineHeight: '3.8'
                                }}>내 정보에 등록된 휴대폰 인증 ({this.state.limitedMobileNumber})</div>
                                <button type="button" name="" style={{ borderRadius: '10px' }} onClick={this.sendAuthenticationNumber.bind(this)}>인증번호 보내기</button>
                            </div>
                            <div className="box-input box-input-num">
                                <input ref={c=>this.authenticationNumber=c} type="text" className="input" placeholder="인증번호"/>
                                <span ref={c=>this.authenticationTimerArea=c} className="time">03:00</span>
                            </div>
                            <div className="box-button">
                                <button type="button" name="" onClick={this.checkAuthenticationNumber.bind(this)}>확인</button>
                            </div>
                        </div>
                        <div className="user-box find-step find-step-3" style={{ display: 'none' }}>
                            <div className="box-title">{this.userId && this.userId.value} 님, 아래 정보를 입력하시면 <br/>내 정보에 등록된 휴대폰 번호로 인증번호가 전송됩니다</div>
                            <div className="box-input">
                                <input ref={c=>this.newPassword=c} type="password" className="input" placeholder="새로운 비밀번호를 입력하세요."
                                   onChange={this.checkPasswordRule.bind(this)}/>
                            </div>
                            <div className="box-alert">
                                <p id={'userPasswordEmpty'} className="box-alert-no" style={{ display: 'none' }}>비밀번호를 입력해주세요.</p>
                                <p id={'userPasswordRule'} className="box-alert-no" style={{ display: this.state.checkUserPasswordRule === false? 'block': 'none' }}>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</p>
                            </div>
                            <div className="box-button">
                                <button type="button" name="" onClick={this.changePassword.bind(this)}>변경하기</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    /**
     * 스텝이동
     * @param number
     */
    displayFindStep(number) {
        const findStepList = document.querySelectorAll('.find-step');

        for(let i=0; i<findStepList.length; i++) {
            const step = findStepList[i];
            step.style.display = 'none'
        }
        document.querySelector(`.find-step-${number}`).style.display = 'block';
    }

    /**
     * 아이디 입력
     */
    checkUserId() {
        if(!this.userId.value) {
            document.getElementById('userIdEmpty').style.display = 'block';
            this.userId.focus();
            return false;
        } else {
            document.getElementById('userIdEmpty').style.display = 'none';
        }

        /* 아이디 인증 */
        axiosInstance.post('/v1/compa/user/password', JSON.stringify({
            id: this.userId.value
        }))
            .then((result)=>{
                console.log('result : ', result.data);
                this.setState({
                   limitedMobileNumber: result.data
                });
                this.displayFindStep(2);
            });
    }

    /**
     * 인증 타이머
     */
    setAuthenticationTimer() {
        let time = 180;
        let min = 0;
        let sec = 0;

        this.authenticationTimer = setInterval(()=>{
            min = parseInt(time / 60);
            sec = time % 60;

            this.authenticationTimerArea.textContent = `${min}분 ${sec}초`;
            time--;

            if(time < 0) {
                clearInterval(this.authenticationTimer);
                this.authenticationTimerArea.textContent = '시간초과';
                this.setState({
                    isAuthenticationSuccess: false
                })
            }
        }, 1000);
    }

    /**
     * 인증번호 발송
     */
    sendAuthenticationNumber() {
        this.setState({
            isAuthenticationSuccess: null
        });
        this.authenticationNumber.value = '';   // 인증번호 초기화
        clearInterval(this.authenticationTimer);
        this.setAuthenticationTimer();

        axiosInstance.post('/v1/compa/user/sms/password', JSON.stringify({
            id: this.userId.value
        }))
            .then(()=>{
                alert('인증번호가 발송되었습니다.');
            });

    }

    /**
     * 인증번호 확인
     */
    checkAuthenticationNumber() {
        axiosInstance.post('/v1/compa/user/sms/valid/password', JSON.stringify({
            id: this.userId.value,
            authNo: this.authenticationNumber.value
        }))
            .then(()=>{
                this.setState({
                    isAuthenticationSuccess: true
                });
                this.displayFindStep(3);
            })
            .catch(()=>{
                this.setState({
                    isAuthenticationSuccess: false
                });
            })
            .finally(()=>{
                this.authenticationNumber.value = '';   // 인증번호 초기화
                clearInterval(this.authenticationTimer);
            });
    }

    /**
     * 비밀번호 규칙확인
     * @return {boolean}
     */
    checkPasswordRule() {
        document.getElementById('userPasswordEmpty').style.display = !this.newPassword.value? 'block': 'none';

        const pwRegExp = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

        if(!pwRegExp.test(this.newPassword.value)) {
            this.setState({
                checkUserPasswordRule: false
            });
            this.newPassword.focus();
            return false;
        } else {
            this.setState({
                checkUserPasswordRule: true
            });
        }
    }

    /**
     * 비밀번호 변경
     */
    changePassword() {
        if(!this.state.checkUserPasswordRule) return;
        document.getElementById('userPasswordEmpty').style.display = !this.newPassword.value? 'block': 'none';

        axiosInstance.put('/v1/compa/user/new-password', JSON.stringify({
            id: this.userId.value,
            newPassword: this.newPassword.value
        }))
            .then(()=>{
                alert('정상적으로 변경되었습니다.');
                this.props.history.push('/ipservice/');
            })
    }
}

export default FindPassword;

import {
    CompetitorsLandscape,
    CorporateNationalityDistribution, MajorTechnicalFieldDistribution,
    NumberOfPatentsByCountry, NumberOfPatentsPerYear,
    PatentDisputesByCountry, PatentRatingByCountry, TechnologyLevelByCompany,
    TopMajorEnterprises, TopicClassification
} from '../chart';

/**
 * 차트 정보
 * @type {{sixth: {component: TopicClassification, name: string}, tenth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, third: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, seventh: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, eighth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, ninth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, fifth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, fourth: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, first: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}, second: {component: (function({isLoading: *, chartData?: *, chartType?: *, onExcelDownload: *}): *), name: string}}}
 */
export const CHART_INFO = {
    'first': {
        name: '연도별 특허건수',
        component: NumberOfPatentsPerYear
    },
    'second': {
        name: '국가별 특허건수',
        component: NumberOfPatentsByCountry
    },
    'third': {
        name: '주요기업 Top15',
        component: TopMajorEnterprises
    },
    'fourth': {
        name: '기업 국적 분포',
        component: CorporateNationalityDistribution
    },
    'fifth': {
        name: '주요 기술분야 분포',
        component: MajorTechnicalFieldDistribution
    },
    'sixth': {
        name: '주제어 분류',
        component: TopicClassification
    },
    'seventh': {
        name: '국가별 특허평가등급',
        component: PatentRatingByCountry
    },
    'eighth': {
        name: '기업별 기술 수준',
        component: TechnologyLevelByCompany
    },
    'ninth': {
        name: '국가별 특허분쟁 현황',
        component: PatentDisputesByCountry
    },
    'tenth': {
        name: '경쟁자 Landscape',
        component: CompetitorsLandscape
    },
}

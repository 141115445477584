import React, {Component} from 'react';

import {axiosInstance} from '../../common/Request';
import {CHART_INFO} from "../chart/chartConstant";


export default class SearchChartView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            queryId: null,
            isLoading: false,
            activeChartId: 'first',
            chartData: null,
            countryNames: []
        }
        this.countryInfo = [
            { code: 'KR', name: '한국' },
            { code: 'JP', name: '일본' },
            { code: 'US', name: '미국' },
            { code: 'EP', name: '유럽' },
            { code: 'CN', name: '중국' },
        ]
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.queryId !== nextProps.queryId) {
            this.handleClickChart(this.state.activeChartId, nextProps.queryId, nextProps.countries);
        }
        if(this.props.countries !== nextProps.countries) {
            const countryNames = this.countryInfo.filter((info)=>{
                return nextProps.countries.indexOf(info.code) > -1;
            }).map((info)=>{
                return info.name;
            });
            this.setState({ countryNames });
        }

        if(!nextProps.queryId && this.props.queryId !== nextProps.queryId) {
            this.setState({
                chartData: null
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state.activeChartId !== nextState.activeChartId) {
            return true;
        }
        if(this.state.isLoading !== nextState.isLoading) {
            return true;
        }
        if(this.props.queryId !== nextProps.queryId) {
            return true;
        }
        return false;
    }

    render() {
        const { activeChartId, isLoading, chartData, countryNames, ldavisData } = this.state;
        const { queryId, countries, company, keywords } = this.props;

        const chartOrder = ['first', 'second', 'ninth', 'third', 'fourth', 'eighth', 'tenth', 'fifth', 'seventh', 'sixth'];
        const Chart = CHART_INFO[activeChartId].component;

        return (
            <div className="service-content">
                {
                    queryId && (
                        <div className="service-head">
                            {company? (<span><b>{company} 회사</b>가</span>): null} <b>{countryNames.join(', ')}</b>에 보유하고 있는<br/>
                            <b>{keywords.join(', ')}</b>기술을 분석한 내용입니다.
                        </div>
                    )
                }
                <ul className="service-tab-menu">
                    {
                        chartOrder.map((chartId, idx)=>{
                            return <li key={`chartButton${idx}`} className={activeChartId === chartId? 'active': ''}><a id={activeChartId} onClick={this.handleClickChart.bind(this, chartId, queryId, countries)}>{CHART_INFO[chartId].name}</a></li>
                        })
                    }
                </ul>
                <div className="service-tab-content">
                    <Chart chartType={activeChartId} chartData={chartData} isLoading={isLoading} onExcelDownload={this.excelDownload.bind(this)}/>
                </div>
            </div>
        );
    }

    /**
     * 차트 선택 및 통계데이터 조회
     * @param chartId
     */
    handleClickChart(chartId, queryId, countries) {
        console.log('chartId : ', chartId);
        if(chartId === this.state.activeChartId && queryId === this.state.queryId) return;
        if(!queryId) {
            this.setState({
                activeChartId: chartId
            });
            return false;
        }

        this.setState({ isLoading: true, chartData: null });

        axiosInstance.get('/v1/compa/graph', {
            params: {
                queryId,
                countries,
                type: chartId
            }
        })
            .then((result)=>{
                console.log('result : ', result);
                this.setState({
                    queryId,
                    activeChartId: chartId,
                    chartData: result.data && result.data.data
                });
            })
            .catch((err)=>{
                console.error('err : ', err);
                this.setState({
                    queryId: null,
                    activeChartId: chartId,
                    chartData: null,
                    ldavisData: null
                });
            })
            .finally(()=>{
                this.setState({ isLoading: false });
            });

    }

    /**
     * 엑셀 다운로드
     * @param chartType
     * @param chartData
     */
    excelDownload(chartType, chartData) {
        axiosInstance.post('/v1/compa/excel', JSON.stringify({
            type: chartType,
            data: chartData
        }), { responseType: 'blob' })
            .then((response)=>{
                const chartName = CHART_INFO[chartType].name.replace(' ', '_');
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${chartName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }
}

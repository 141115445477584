import React from "react";
import {Link} from "react-router-dom";
import {logout} from "../pages/authentication/authenticationUtil";


const ContentsHeader = (props) => {
    const sessionStr = sessionStorage.getItem('user_info');
    const session = sessionStr? JSON.parse(sessionStr): null;

    return (
        <div className="location">
            <h2 className="loc-title"><Link to={'/ipservice/'}>인공지능 기반 IP 전략 서비스</Link></h2>
            {
                session
                    ? (
                        <div className="loc-user">
                            { session.remain <= 0? '무료체험이 종료되었습니다.' : `무료체험 중입니다. (남은 기한 : ${session.remain}일)` }
                            <Link to={'/ipservice/member/memberInfo'}><span className="icon"><img src="/images/common/loc-user_icon.png" alt="" /></span></Link>
                            <a onClick={logout.bind(this, props.history)}><span className="icon">Logout</span></a>
                        </div>
                    ) : (
                        <div className="loc-btn">
                            <Link to={'/ipservice/login'}>로그인</Link>
                            <Link to={'/ipservice/member/join'}>회원가입</Link>
                        </div>
                    )
            }
        </div>
    );
}

export default ContentsHeader;
